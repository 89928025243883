import React, { useEffect, useRef, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';
import wasteReceptionsService from '../../service/api/WasteReceptionsService';
import weighingsService from '../../service/api/WeighingsService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { ReceptionStatus, WasteReception } from '../../types/WasteReception';
import { ResidentCard } from '../../types/ResidentCard';
import { WasteReceptionItem } from '../../types/WasteReceptionItem';
import { WasteTypeDetails } from '../../types/WasteType';
import { Unit, WasteGroup } from '../../types/WasteGroup';
import { groupBy } from '../../utils/group-by';
import { Weighing, WeighingStatus } from '../../types/Weighing';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';

interface FormItem extends WasteReceptionItem {
  weighingNumberAutocomplete: object | string;
}

interface FormValuesProps extends Omit<WasteReception, 'items'> {
  items: FormItem[];
}

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  wasteReception: WasteReception;
  availableResidentCards: ResidentCard[];
  wasteTypes?: WasteTypeDetails[];
  wasteGroups?: WasteGroup[];
  propertyId?: string;
};

function WasteReceptionDialog({
  dialogVisible,
  closeAddEditModal,
  wasteReception,

  availableResidentCards,
  wasteTypes,
  wasteGroups,
  propertyId,
}: Props) {
  const INIT_FORM_STATE: FormValuesProps = {
    id: null,
    residentCardId: null,
    status: ReceptionStatus.OPEN,
    items: [],
    propertyId: propertyId,
  };

  const readonly = wasteReception?.status === ReceptionStatus.CLOSED;
  if (readonly) {
    wasteTypes = wasteReception.items.map((i) => {
      return {
        code: i.wasteTypeCode,
        id: i.wasteTypeId,
        name: i.wasteTypeName,
        wasteGroupId: i.wasteGroupId,
        limit: i.remainingLimit === null ? null : i.amount + i.remainingLimit,
        usedLimit: i.amount,
      };
    });
    wasteGroups = wasteReception.groups.map((g) => {
      return {
        id: g.wasteGroupId,
        name: g.wasteGroupName,
        limit: g.amount + g.remainingLimit,
        unit: g.wasteGroupUnit,
      };
    });
  }

  const toast = useRef(null);

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const loggedUserContext = useSelector((state: any) => state.user.context);

  const [weighings, setWeighings] = useState<Weighing[]>([]);
  const [filteredWeighings, setFilteredWeighings] = useState([]);

  const [weighingInProgress, setWeighingInProgress] = useState<boolean>(false);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    setWeighings([]);
    closeAddEditModal();
  };

  const currentPropertyId = wasteReception?.propertyId ?? propertyId;

  const residentCards = availableResidentCards
    ?.filter((rc) => rc.propertyId === currentPropertyId)
    ?.map((r) => {
      return { label: r.number, value: r.id };
    });

  const { t } = useTranslation();

  const getUsedLimitForGroup = (wasteGroupId: string) => {
    return wasteTypes
      .filter((i) => i.wasteGroupId === wasteGroupId)
      .reduce((acc, current) => {
        return acc + current.usedLimit;
      }, 0);
  };

  if (wasteGroups && wasteTypes) {
    wasteGroups = JSON.parse(JSON.stringify(wasteGroups));
    for (const wg of wasteGroups) {
      if (wg.limit !== null) {
        wg.limit -= getUsedLimitForGroup(wg.id);
      }
    }
  }

  const getWasteGroup = (id: string) => {
    return wasteGroups.find((wg) => wg.id === id);
  };

  useEffect(() => {
    const items = wasteTypes?.map((wt: WasteTypeDetails): FormItem => {
      return {
        wasteTypeId: wt.id,
        wasteTypeName: wt.name,
        wasteTypeCode: wt.code,
        amount: null,
        weighingNumber: '',
        wasteGroupId: wt.wasteGroupId,
        wasteLimit: wt.limit !== null ? wt.limit - wt.usedLimit : null,
        weighingNumberAutocomplete: null,
      } as unknown as FormItem;
    });

    if (items && wasteReception?.items) {
      for (const existing of wasteReception?.items) {
        const item = items.find((i) => i.wasteTypeId === existing.wasteTypeId);
        if (item) {
          item.amount = existing.amount;
          item.weighingNumber = existing.weighingNumber || '';
          item.weighingId = existing.weighingId || null;

          item.weighingNumberAutocomplete = existing.weighingNumber;

          if (item.weighingId) {
            const weighing = weighings.find((w) => w.id === item.weighingId);
            if (weighing) {
              item.weighingNumberAutocomplete = weighing;
            }
          }
        }
      }
    }

    if (dialogVisible && wasteReception) {
      setInitFormValues({
        id: wasteReception.id,
        propertyId: wasteReception.propertyId,
        residentCardId: wasteReception.residentCardId,
        status: wasteReception.status,
        items: items,
      });
    } else {
      setInitFormValues({ ...INIT_FORM_STATE, items: items });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible, weighings]);

  const formik = useFormik({
    initialValues: initFormValues,
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      const dataToSend = { ...formData };
      dataToSend.items = formData.items.filter((i) => i.amount !== null);
      for (const item of dataToSend.items) {
        if (item.weighingNumber && !item.weighingId) {
          const weighing = weighings.find((w) => w.number === item.weighingNumber);
          item.weighingId = weighing?.id;
        }
      }
      if (!formData.id) {
        wasteReceptionsService
          .create(dataToSend)
          .then((_res: any) => {
            hideDialog();
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        wasteReceptionsService
          .edit(dataToSend, dataToSend.id)
          .then(() => {
            hideDialog();
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        disabled={readonly}
        onClick={formik.submitForm}
      />
      <Button
        type="submit"
        label={t('wasteReceptions.save_and_close')}
        icon="pi pi-check"
        className="p-button-text"
        disabled={!!weighings.find((w) => w.status !== WeighingStatus.CLOSED) || readonly}
        onClick={() => {
          formik.setFieldValue('status', ReceptionStatus.CLOSED, false);
          formik.submitForm();
        }}
      />
    </>
  );

  const getSumForGroup = (wasteGroupId: string) => {
    return formik.values.items
      .filter((i) => i.wasteGroupId === wasteGroupId)
      .reduce((acc, current) => {
        return acc + current.amount;
      }, 0);
  };

  const groupLimitExceeded = (wasteGroupId: string) => {
    const sum = getSumForGroup(wasteGroupId);
    const limit = getWasteGroup(wasteGroupId).limit;

    return limit !== null && sum > limit;
  };

  const autocompleteSearch = (event) => {
    let filtered = weighings.filter((w) => w.status === WeighingStatus.CLOSED);

    if (!!event.query.trim().length) {
      filtered = weighings.filter((w) => {
        return w.number.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    setFilteredWeighings(filtered);
  };

  const calculateRemainingAmount = (weighingNumber: string): number => {
    const used = formik.values.items
      .filter((i) => i.weighingNumber === weighingNumber)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

    const limit = weighings.find((w) => w.number === weighingNumber)?.value;

    if (!limit) {
      return 0;
    }

    return limit - used;
  };

  const itemTemplate = (item, index) => {
    return (
      <React.Fragment key={index}>
        <div className="field col-4">
          <label htmlFor={`items[${index}].amount`}>{`${(item as any).wasteTypeName} (${t(
            `wasteGroups.units_short.${getWasteGroup(item.wasteGroupId).unit}`
          )}) - ${item.wasteTypeCode}`}</label>
          <InputNumber
            id={`items[${index}].amount`}
            maxFractionDigits={3}
            min={0}
            max={1000000}
            disabled={readonly}
            value={formik.values.items[index].amount}
            onValueChange={formik.handleChange}
            className={
              !readonly &&
              ((item.wasteLimit != null && item.amount > item.wasteLimit) || groupLimitExceeded(item.wasteGroupId))
                ? 'p-invalid'
                : undefined
            }
          />
        </div>
        <div className="field col-4">
          <label htmlFor={`items[${index}].weighingNumber`}>{t('wasteReceptionItems.weighing_number')}</label>
          <AutoComplete
            id={`items[${index}].weighingNumber-autocomplete`}
            value={formik.values.items[index].weighingNumberAutocomplete}
            field="number"
            suggestions={filteredWeighings}
            disabled={readonly || getWasteGroup(item.wasteGroupId).unit === Unit.ITEM}
            onChange={(e) => {
              formik.setFieldValue(`items[${index}].weighingNumberAutocomplete`, e.value, false);
              if (typeof e.value === 'object') {
                formik.setFieldValue(`items[${index}].weighingId`, e.value.id, false);
                formik.setFieldValue(`items[${index}].weighingNumber`, e.value.number, false);
                if (formik.values.items[index].amount == null) {
                  const remaining = calculateRemainingAmount(e.value.number);
                  if (remaining > 0) {
                    formik.setFieldValue(`items[${index}].amount`, remaining, false);
                  }
                }
              } else {
                formik.setFieldValue(`items[${index}].weighingNumber`, e.value, false);
                formik.setFieldValue(`items[${index}].weighingId`, null, false);
              }
            }}
            completeMethod={autocompleteSearch}
            dropdown
            autoHighlight
          />
        </div>
        <div className="field col-4">
          <label htmlFor={`items[${index}].wasteLimit`}>
            {readonly ? t('wasteTypes.remaining_limit') : t('wasteTypes.limit')}
          </label>
          <InputNumber id={`items[${index}].wasteLimit`} value={item.wasteLimit} readOnly={true} disabled={true} />
        </div>
      </React.Fragment>
    );
  };

  // we group types by waste group in the function below
  // but we have a flat list of waste types in formik
  // so we need to find the right index, for input to be bound correctly
  const getFormikIndex = (wasteTypeId: string) => {
    const result = formik.values.items.findIndex((item) => item.wasteTypeId === wasteTypeId);
    return result;
  };

  const createInputs = (items: FormItem[]) => {
    const itemsGroupped = groupBy(items, 'wasteGroupId');
    const arr = Array.from(itemsGroupped);
    return arr
      .map((ar, index) => {
        const [wasteGroupId, items] = ar;
        const wg = getWasteGroup(wasteGroupId);
        if (!wg) {
          return null;
        }

        const unit = t(`wasteGroups.units_short.${wg.unit}`);
        const usedLimit = items.reduce((acc, current) => {
          return acc + current.amount || 0;
        }, 0);
        return (
          <Fieldset
            toggleable={true}
            key={index}
            className="col-12"
            legend={`${wg.name}${
              wg.limit !== null
                ? ` (${t('wasteGroups.remaining_limit')} ${parseFloat(wg.limit.toFixed(3))} ${unit} ${t(
                    'wasteGroups.used'
                  )} ${parseFloat(usedLimit.toFixed(3))} ${unit})`
                : ''
            }`}
          >
            <div className="col-12 grid">
              {items?.map((item) => {
                return itemTemplate(item, getFormikIndex(item.wasteTypeId));
              })}
            </div>
          </Fieldset>
        );
      })
      .filter(Boolean);
  };

  const loadWeighings = async () => {
    if (!wasteReception?.id) {
      return;
    }

    const data = await weighingsService.getAllForWasteReception(wasteReception.id);
    setWeighings(data);
  };

  useEffect(() => {
    if (dialogVisible) {
      loadWeighings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasteReception, dialogVisible]);

  const weighingsTableHeader = (
    <div className="table-header">
      {t('weighings.header')}
      <span>
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('weighings.start')}
          disabled={readonly || !loggedUserContext.scaleoEnabled}
          className="p-button ml-2"
          onClick={async () => {
            setWeighingInProgress(true);
            try {
              await weighingsService.create({ wasteReceptionId: wasteReception.id });
              await loadWeighings();
            } catch (error) {
              toast?.current?.show({
                severity: 'error',
                summary: t('common.error'),
                detail: t('weighings.weighings_error'),
                life: 4000,
              });
            } finally {
              setWeighingInProgress(false);
            }
          }}
        ></Button>
      </span>
    </div>
  );

  const weighingsActionTemplate = (data: Weighing) => (
    <span>
      <Button
        type="button"
        icon="pi pi-check-circle"
        tooltip={t('weighings.close')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        disabled={data.status === WeighingStatus.CLOSED || readonly || !loggedUserContext.scaleoEnabled}
        onClick={async () => {
          setWeighingInProgress(true);
          try {
            await weighingsService.closeWeighing(data.id);
            await loadWeighings();
          } catch {
            toast?.current?.show({
              severity: 'error',
              summary: t('common.error'),
              detail: t('weighings.weighings_error'),
              life: 4000,
            });
          } finally {
            setWeighingInProgress(false);
          }
        }}
      ></Button>
    </span>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Toast ref={toast} />
      <Dialog
        visible={dialogVisible}
        header={t('wasteReceptions.waste_reception_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '80vw' }}
      >
        <div className="grid crud-demo">
          <div className="col-12">
            <div className="card">
              <DataTable
                value={weighings}
                className="p-datatable-customers"
                rows={10}
                dataKey="id"
                rowHover
                emptyMessage={t('wasteReceptions.empty_weighings_message')}
                header={weighingsTableHeader}
                loading={weighingInProgress}
              >
                <Column field="value" header={t('weighings.value')}></Column>
                <Column field="valueTare" header={t('weighings.value_tare')}></Column>
                <Column field="valueGross" header={t('weighings.value_gross')}></Column>
                <Column field="number" header={t('weighings.number')}></Column>
                <Column
                  headerStyle={{ width: '12rem' }}
                  header={t('common.actions')}
                  align="center"
                  body={weighingsActionTemplate}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>

        <form>
          <div className="col-12 formgrid grid">
            <div className="field col-12">
              <label htmlFor="residentCardId">{t('wasteReceptions.resident_card')}</label>
              <Dropdown
                id="residentCardId"
                value={formik.values.residentCardId}
                onChange={formik.handleChange}
                options={residentCards}
                placeholder={t('common.select')}
                showClear={true}
                disabled={readonly}
                emptyMessage={t('wasteReceptions.resident_cards_empty_message')}
                className={formik.touched.residentCardId && formik.errors.residentCardId && 'p-invalid'}
                // BEWARE somehow touched is not set when clearing the dropdown - needs to be investigated
                // (low priority, for now this field is optional anyway)
              />
              <FormErrorMessage fieldName="residentCardId" formikInstance={formik} />
            </div>

            {formik.values.items && createInputs(formik.values.items)}
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default WasteReceptionDialog;
